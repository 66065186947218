import React from 'react';
import ContactForm from './../contactForm/ContactForm';
import styles from './ContactPage.module.css';

const ContactPage = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Get in Touch</h1>
      <p className={styles.subHeader}>
        We’d love to hear from you! Whether you’re looking to hire, collaborate,
        or just say hello, fill out the form below and we’ll get back to you as
        soon as possible.
      </p>
      <ContactForm />
    </div>
  );
};

export default ContactPage;
