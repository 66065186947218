import React from 'react';
import { FaCog, FaRobo, FaTasks } from 'react-icons/fa';
import { SiZapier, SiIntegromat, SiAirtable } from 'react-icons/si';
import styles from './BPAComponent.module.css';

const BPAComponent = () => {
  return (
    <section className={styles.bpaSection}>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.icon}><FaCog /></div>
          <h2 className={styles.title}>Business Process Automation</h2>
        </header>
        <p className={styles.intro}>
          I specialize in automating complex business processes to increase efficiency, reduce errors, and free up valuable time. From integrating cloud tools to building custom automation workflows, I ensure that your business operations run smoothly and seamlessly.
        </p>

        <div className={styles.toolsSection}>
          <h3 className={styles.subTitle}>Tools & Platforms</h3>
          <div className={styles.toolsGrid}>
            <div className={styles.toolItem}><SiZapier /> Zapier</div>
            {/* <div className={styles.toolItem}><SiIntegromat /> Integromat (Make)</div> */}
            <div className={styles.toolItem}><SiAirtable /> Airtable</div>
            {/* <div className={styles.toolItem}><FaRobo /> Robotic Process Automation (RPA)</div> */}
            <div className={styles.toolItem}><FaTasks /> Task Automation</div>
          </div>
        </div>

        <div className={styles.cta}>
          <p className={styles.ctaText}>Let me help you streamline your business processes with cutting-edge automation tools and techniques.</p>
          <a href="/contact" className={styles.ctaButton}>Start Automating Now</a>
        </div>
      </div>
    </section>
  );
};

export default BPAComponent;
