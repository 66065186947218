import React, { useState } from "react";
import styles from "./HeaderComponent.module.css";

const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerLogo}>
        {/* <a className={styles.linkk} href="www.linkedin.com/in/daniel-r-rangel-dc88" target="_blank"> */}

          <h1>Daniel Rangel</h1>
        {/* </a> */}
        <p>Platform Engineer | Salesforce Developer</p>
      </div>
      <button className={styles.menuToggle} onClick={toggleMenu}>
        <span className={styles.menuIcon}>&#9776;</span>
      </button>
      <nav className={`${styles.headerNav} ${isOpen ? styles.open : ""}`}>
        <ul className={styles.navList}>
          <li><a href="/" onClick={() => setIsOpen(false)}>Home</a></li>
          {/* <li><a href="#skills" onClick={() => setIsOpen(false)}>Skills</a></li> */}
          <li><a href="/projects" onClick={() => setIsOpen(false)}>Projects</a></li>
          {/* <li><a href="#testimonials" onClick={() => setIsOpen(false)}>Testimonials</a></li> */}
          <li><a href="/contact" onClick={() => setIsOpen(false)}>Contact</a></li>
        </ul>
      </nav>
      <div className={styles.headerButtons}>
      <a href="/Daniel.Rangel.Resume.pdf" className={`${styles.btn} ${styles.primaryBtn}`} download>
        Download Resume
      </a>

        <a href="/contact" className={`${styles.btn} ${styles.secondaryBtn}`}>
          Let's Collaborate
        </a>
      </div>
    </header>
  );
};

export default HeaderComponent;
