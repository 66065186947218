import React from 'react';
import { FaReact, FaHtml5, FaCss3Alt, FaJsSquare, FaNodeJs } from 'react-icons/fa';
import { SiPostgresql, SiNextdotjs, SiWordpress } from 'react-icons/si';
import styles from './WebDevComponent.module.css';

const WebDevComponent = () => {
  return (
    <section className={styles.webDevSection}>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.icon}><FaReact /></div>
          <h2 className={styles.title}>Web Development & SEO Optimization</h2>
        </header>
        <p className={styles.intro}>
          Whether you're building a website from scratch or optimizing an existing one, I specialize in creating fast, scalable, and SEO-optimized websites using the latest technologies. I ensure that every site I build is not only visually appealing but also performs well across all devices and ranks higher on search engines.
        </p>
        
        <div className={styles.toolsSection}>
          <h3 className={styles.subTitle}>Technologies & Tools</h3>
          <div className={styles.toolsGrid}>
            <div className={styles.toolItem}><FaReact /> React</div>
            <div className={styles.toolItem}><FaHtml5 /> HTML5</div>
            <div className={styles.toolItem}><FaCss3Alt /> CSS3</div>
            <div className={styles.toolItem}><FaJsSquare /> JavaScript</div>
            <div className={styles.toolItem}><FaNodeJs /> Node.js</div>
            <div className={styles.toolItem}><SiPostgresql /> PostgreSQL</div>
            <div className={styles.toolItem}><SiNextdotjs /> Next.js</div>
            <div className={styles.toolItem}><SiWordpress /> WordPress</div>
          </div>
        </div>

        <div className={styles.cta}>
          <p className={styles.ctaText}>Let's create a website that not only looks great but also performs at its best and ranks high on search engines.</p>
          <a href="/contact" className={styles.ctaButton}>Lets Get Started</a>
        </div>
      </div>
    </section>
  );
};

export default WebDevComponent;
