import React from "react";
import styles from "./ProjectsPage.module.css";
import { 
  FaCubes, 
  FaToolbox, 
  FaChartLine, 
  FaEnvelopeOpenText, 
  FaServer, 
  FaChartPie, 
  FaShoppingCart, 
  FaUsers, 
  FaPaintBrush, 
  FaCut, 
  FaSalesforce, 
  FaDatabase, 
  FaTools, 
  FaChartBar, 
  FaHeartbeat,
  FaCheckCircle,
  FaPalette
} from "react-icons/fa";
import { SiNextdotjs, SiPostgresql, SiJavascript, SiReact } from "react-icons/si";

const ProjectsPage = () => {
  const projects = [
    {
      title: "Salesforce Portal",
      description: "Streamline workforce data and recruitment with a fully custom Salesforce portal designed for scalability and ease of use.",
      features: [
        "Granular employee access controls",
        "Seamless job posting and one-click applications",
        "Real-time workforce insights and analytics",
      ],
      tech: ["Salesforce", "Apex", "LWC", "CMS"],
      icon: <FaSalesforce />,
    },
    // {
    //   title: "Integrated Lead Reporting System",
    //   description: "Centralized data integration with real-time insights for sales efficiency. Ideal for businesses tracking actionable leads.",
    //   features: [
    //     "Dynamic charts for real-time visualization",
    //     "Unified data from multiple systems",
    //     "Highly responsive and scalable",
    //   ],
    //   tech: ["Google Apps Script", "PostgreSQL", "JavaScript"],
    //   icon: <FaChartBar />,
    // },
    // {
    //   title: "Contractor Management Tool",
    //   description: "Comprehensive tool to help contractors manage finances, projects, and clients with effortless tracking and automation.",
    //   features: [
    //     "Digital invoicing and estimates",
    //     "Integrated income and expense tracking",
    //     "Secure client management database",
    //   ],
    //   tech: ["PostgreSQL", "FastAPI", "React", "Next.js"],
    //   icon: <FaTools />,
    // },
    {
      title: "Data Visualization Dashboard",
      description: "A sleek dashboard for visualizing complex data using advanced charts and intuitive user flows.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      tech: ["React", "Next.js", "D3.js"],
      icon: <FaDatabase />,
    },
    {
      title: "Healthcare Management",
      description: "Designed for healthcare professionals, this secure web app simplifies patient and encounter tracking. With robust login-protected access and user-friendly workflows, it ensures seamless management of sensitive data while saving time and reducing errors.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaHeartbeat />,
      tech: ["React", "Node.js", "PostgreSQL", "AWS"]
    },
    {
      title: "E-Commerce Platform",
      description: "A sleek and intuitive e-commerce solution for fitness apparel. Customers can shop by category, size, or gender, with an ultra-smooth checkout process powered by Stripe integration. Boost sales with a platform that combines ease of use and security.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaShoppingCart />,
      tech: ["Next.js", "Stripe", "TypeScript", "MongoDB"]
    },
    {
      title: "Dynamic Modular Content Platform",
      description: "Built with modular components for efficiency, this platform features a robust CMS with customizable blogs and a tailored Strapi instance. Perfect for scaling businesses looking to simplify content creation and management while staying ahead of the competition.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaCubes />,
      tech: ["React", "Strapi", "GraphQL", "Docker"]
    },
    // {
    //   title: "Innovative Contractor Management Tool",
    //   description: "Empower contractors with an all-in-one web app to manage finances and client relationships. From storing contacts to generating invoices, estimates, and tracking project progress, this tool ensures maximum efficiency and professionalism.",
    //   features: [
    //     "Interactive data visualizations",
    //     "Customizable filters and reports",
    //     "Scalable for enterprise use",
    //   ],
    //   icon: <FaToolbox />,
    //   tech: ["FastAPI", "React", "PostgreSQL", "Docker"]
    // },
    {
      title: "Salesforce Revenue",
      description: "A custom Salesforce solution to track and calculate revenue, excluding weekends and holidays. Designed for flexibility, it works with any object and provides insights for both past and future years to drive data-driven decision-making.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaChartLine />,
      tech: ["Salesforce", "Apex", "LWC", "Flow"]
    },
    {
      title: "Client Feedback",
      description: "Request client feedback efficiently with single-record or bulk list view options. Includes customizable email templates and a digital experience site for non-Salesforce users to submit data and create related records seamlessly.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaEnvelopeOpenText />,
      tech: ["Salesforce", "Apex", "Digital Experience", "JavaScript"]
    },
    {
      title: "Salesforce Migration",
      description: "Revolutionized a Salesforce account by migrating all data, automations, and sites to a clean new instance. Reduced costs, eliminated waste data, and streamlined processes for future growth.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaServer />,
      tech: ["Salesforce", "Apex", "Data Loader", "Flow"]
    },
    {
      title: "Lead Reporting",
      description: "Integrated Gmail, Google App Scripts, and Google Sheets to develop an automated lead reporting system. Delivered daily and weekly reports with actionable insights for better decision-making and performance tracking.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaChartPie />,
      tech: ["Google App Scripts", "Google Sheets", "HTML", "CSS", "JavaScript"]
    },
    // {
    //   title: "Effortless E-Commerce Platform",
    //   description: "Built a fitness apparel e-commerce app with category, size, and gender filters. Integrated Stripe for secure payments, delivering a seamless shopping experience designed to boost conversions.",
    //   features: [
    //     "Interactive data visualizations",
    //     "Customizable filters and reports",
    //     "Scalable for enterprise use",
    //   ],
    //   icon: <FaShoppingCart />,
    //   tech: ["React", "Node.js", "Stripe", "CSS"]
    // },
    {
      title: "Staffing Solutions",
      description: "Created a tailored process for staffing agencies to search and filter candidates based on job requirements. Enhanced efficiency by enabling users to create and manage candidate lists directly within Salesforce.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaUsers />,
      tech: ["Salesforce", "Apex", "Lightning Web Components", "Flow"]
    },
    {
      title: "Contractor Management",
      description: "Developed a comprehensive web app for contractors to manage finances and client relationships. Features include contact storage, professional invoice generation, project status tracking, and detailed estimates for seamless project execution.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaToolbox />,
      tech: ["PostgreSQL", "FastAPI", "React", "Node.js"],
      logo: '/saglogo.png',
      url: 'https://www.saguaropallets.com/'
    },
    {
      title: "Nail Salon Portfolio",
      description: "Designed a visually stunning website for a nail salon, showcasing previous work and providing an easy booking system. Delivered an affordable solution to boost visibility and attract more clients.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaPaintBrush />,
      tech: ["Next.js", "React", "CSS", "TailwindCSS"],
      logo: '/apple-touch-icon.png',
      url: 'https://www.honeyvnails.com/'
    },
    {
      title: "Online Barber",
      description: "Crafted a sleek website for a barbershop, featuring a home page and a gallery of haircut styles. Customers can easily browse contact details and point out their preferred styles, while in-store QR codes provide instant access to the gallery for a seamless experience.",
      features: [
        "Interactive data visualizations",
        "Customizable filters and reports",
        "Scalable for enterprise use",
      ],
      icon: <FaCut />,
      tech: ["Next.js", "React", "QR Code Integration", "CSS"],
      logo: "/barberlogo.jpg",
      url: 'https://www.guerosbarbershop.com/'
    },
    {
      title: "Painting Company Website",
      description: "Developed a professional website for a painting company, showcasing their services, portfolio, and contact options. The site is designed to highlight their expertise and attract new clients through a clean and modern interface.",
      features: [
        "Responsive design for all devices",
        "Service portfolio with high-quality images",
        "Contact form for easy client inquiries",
        "SEO-optimized pages for increased visibility"
      ],
      icon: <FaPalette />,
      tech: ["Next.js", "React", "CSS", "SEO Optimization"],
      logo: "/paintlogo.jpg",
      url: "https://www.paintjg.com/"
    }
    
    // Add more projects as needed
  ];

  return (
    <section className={styles.projectsPage}>
      <div className={styles.container}>
        <h1 className={styles.pageTitle}>Innovative Projects</h1>
        <p className={styles.pageSubtitle}>
          Explore a selection of projects that showcase innovation, technical expertise, and real-world impact. Every project is designed with precision to meet diverse business needs.
        </p>
        <div className={styles.projectsGrid}>
          {projects.map((project, index) => (
            <div className={styles.projectCard} key={index}>
              <div className={styles.projectIcon}>{project.icon}</div>
              <h2 className={styles.projectTitle}>{project.title}</h2>
              {project.logo && (
                <a href={project.url ? project.url: '#'} target="_blank">
                  <img
                    src={project.logo}
                    alt={`${project.title} Logo`}
                    className={styles.projectLogo}
                  />
                </a>
              )}
              <p className={styles.projectDescription}>{project.description}</p>
              <ul className={styles.featuresList}>
                {project.features.map((feature, i) => (
                  <li key={i} className={styles.featureItem}>
                    <span className={styles.featureItemIcon}>
                      <FaCheckCircle />
                    </span>
                    {feature}
                  </li>
                ))}
              </ul>
              <div className={styles.techStack}>
                {project.tech.map((tech, i) => (
                  <span key={i} className={styles.techBadge}>
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className={styles.ctaContainer}>
          <h2 className={styles.ctaTitle}>Ready to bring your idea to life?</h2>
          <p className={styles.ctaSubtitle}>
            Let's collaborate and turn your vision into reality. Whether you're looking for custom web development or an innovative solution, I’m here to help.
          </p>
          <a href="/contact" className={styles.ctaButton}>
            Start Your Project Today!
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProjectsPage;
