import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// Components
import HeaderComponent from './components/headerComponent/HeaderComponent';
import HeroComponent from './components/hero/HeroComponent';
import SkillsComponent from './components/skillsComponent/SkillsComponent';
import ProjectsComponent from './components/projectsComponent/ProjectsComponent';
import ContactComponent from './components/contactComponent/ContactComponent';
import Footer from './components/footer/Footer';
import SalesforceComponent from './components/salesforceComponent/SalesforceComponent';
import WebDevelopmentComponent from './components/webDevComponent/WebDevComponent';
import BPAComponent from './components/bpacomponent/BPAComponent';
import ProjectsPage from './components/projectsPage/ProjectsPage';
import ContactPage from './components/contactPage/ContactPage'
import ThankYouPage from './components/thankYouPage/ThankYouPage'

const App = () => (
  <Router>
    <HeaderComponent />
    <Routes>
      {/* Homepage */}
      <Route
        path="/"
        element={
          <>
            <HeroComponent />
            <SkillsComponent id='skills'/>
            <SalesforceComponent />
            <WebDevelopmentComponent />
            <BPAComponent />
            <ProjectsComponent />
            <ContactComponent />
          </>
        }
      />

      {/* Projects Page */}
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/thank-you" element={<ThankYouPage />} />
      
    </Routes>
    <Footer />
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
