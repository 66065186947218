import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ThankYouPage.module.css'; // Import the CSS Module

const ThankYouPage = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.content}>
        <h1>Thank you for your submission!</h1>
        <p>We will get back to you soon. Feel free to explore more of our site:</p>
        <Link to="/projects" className={styles.link}>Go to Projects</Link>
        <br />
        <Link to="/contact" className={styles.link}>Go to Contact Page</Link>
      </div>

      <footer className={styles.footer}>
        <p>&copy; 2024 Your Company. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ThankYouPage;
