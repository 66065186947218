import React from "react";
import styles from "./SalesforceComponent.module.css";
import { FaSalesforce } from "react-icons/fa";

const SalesforceComponent = () => {
  return (
    <section className={styles.salesforceSection}>
      <div className={styles.container}>
        <div className={styles.header}>
          <FaSalesforce className={styles.icon} />
          <h2 className={styles.title}>
            Supercharge Your Salesforce Platform
          </h2>
        </div>

        <p className={styles.intro}>
          As a certified Salesforce Developer & Admin, I help businesses automate, optimize, and scale their operations with custom Salesforce solutions. Whether it's complex integrations, custom Lightning components, or automation with Apex, I deliver results that make a difference.
        </p>

        <div className={styles.cta}>
          <p className={styles.ctaText}>
            Ready to take your Salesforce org to the next level? Let’s get started!
          </p>
          <a href="/salesforce" className={styles.ctaButton}>
            Maximize Salesforce
          </a>
        </div>
      </div>
    </section>
  );
};

export default SalesforceComponent;
