import React from "react";
import styles from "./HeroComponent.module.css";

const HeroComponent = () => {
  return (
    <section className={styles.hero}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Elevate Your Business with a <span className={styles.highlight}>Lead Engineer</span> Who Delivers.
        </h1>
        <p className={styles.subtitle}>
          I'm Daniel Rangel, a Salesforce Developer and Platform Engineer with a proven track record of building scalable solutions and driving digital transformation. Let's create something exceptional together.
        </p>
        <div className={styles.cta}>
          <a href="/projects" className={`${styles.btn} ${styles.primaryBtn}`}>View My Work</a>
          <a href="/contact" className={`${styles.btn} ${styles.secondaryBtn}`}>Hire Me</a>
        </div>
      </div>
      {/* <div className={styles.imageContainer}>
        <img 
          src="/dw.jpg" 
          alt="Daniel Rangel - Salesforce Developer and Engineer" 
          className={styles.heroImage} 
        />
      </div> */}
    </section>
  );
};

export default HeroComponent;
